.profile-container {
  margin-top: 10rem;
  background-color: black;
  min-height: 48vh;
}

.profile-container .card {
  border-radius: 10px;
}
.profile-container .profile-acc-container{
  display: flex;
  align-items: center;
}
.profile-container .profile-image {
  width: 50px;
  border: 1px solid white;
}

.profile-container .nav-link {
  text-decoration: none;
  color: inherit;
  padding: 10px 0;
  transition: background-color 0.3s;
}

.profile-container .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.profile-container .nav-icon {
  margin-right: 8px;
  padding-left: 5px;
}

.profile-container .logout-icon-container {
  cursor: pointer;
}
.profile-container .logout-icon-container:hover {
  background-color: #a30000 !important;
}

.profile-container .logout-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.profile-container .outlet-container {
  padding: 20px;
  border-radius: 10px;
  max-height: 76vh;
  overflow-y: auto;
}

.profile-container .active-nav {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #00bcd4 !important;
}

