.bg-image-section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    animation: fadeIn 1.5s ease-in-out; /* Fade in effect */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.bg-image-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Overlay to improve text readability */
    z-index: 1;
}

.bg-image-section .heading-container {
    z-index: 2; /* Bring content above the overlay */
    text-transform: uppercase;
    margin-right: 3rem;
    margin-top: 6rem;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeUp 1.2s ease-in-out forwards; /* Fade-up effect */
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.bg-image-section h1 {
    margin-bottom: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 3.5rem;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.6); /* Text shadow for emphasis */
    transition: transform 0.3s ease, text-shadow 0.3s ease;
}

.bg-image-section h1:hover {
    transform: scale(1.05); /* Scale effect on hover */
    text-shadow: 4px 6px 15px rgba(0, 0, 0, 0.8); /* Stronger shadow on hover */
}

.bg-image-section .breadcrumb {
    margin-top: 20px;
    z-index: 2;
    opacity: 0;
    animation: fadeUp 1.5s ease-in-out forwards 0.2s; /* Delayed fade-up effect */
}

.bg-image-section .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
}

.bg-image-section .breadcrumb-item {
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Transition on hover */
}

.bg-image-section .breadcrumb-item:hover {
    color: #f0f0f0;
    text-shadow: 1px 2px 5px rgba(255, 255, 255, 0.5);
}

.bg-image-section a {
    position: relative;
    color: white;
    text-decoration: none;
}

.bg-image-section a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.bg-image-section a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

.bg-image-section a:hover {
    color: #f0f0f0;
}

html {
    scroll-behavior: smooth; /* Smooth scrolling when navigating */
}
