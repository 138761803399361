.pay-policy {
    max-width: 1000px;
}

.pay-policy h1 {
    font-size: 2.5rem;
    color: #ffffff;
}

.pay-policy h2 {
    font-size: 2rem;
    color: #ffffff;
}

.pay-policy p {
    font-size: 1rem;
    line-height: 1.6;
}

.pay-policy ul {
    list-style-type: disc;
    margin-left: 20px;
}

.pay-policy ul li {
    margin-bottom: 10px;
}

.pay-policy a {
    color: #007bff;
    text-decoration: none;
}

.pay-policy a:hover {
    text-decoration: underline;
}