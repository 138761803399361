.header-container {
    top: 40px;
    left: 200px;
    width: 80%;
    height: 80px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);
    z-index: 10;
    transition: all 0.8s ease;
}

.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.header-container .logo {
    font-family: Rubik Glitch;
    padding-left: 2.5rem;
    margin-bottom: 0;
}


.header-container .nav-routes {
    align-items: center;
}

.header-container .nav-links {
    font-family: Roboto;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
}

.header-container .nav-link {
    font-size: 13px;
    margin-bottom: 0;
}

.header-container .item-quantity {
    font-family: 'Saira Stencil One';
    color: black;
    font-size: small;
    position: absolute;
    margin-left: 11px;
    margin-top: -12px;
}


.header-container .active-route {
    font-size: 14px;
    font-style: Regular;
    font-family: Saira Stencil One;
}

.header-container .active-icon {
    font-size: 14px;
}