.acc-setting .card {
    background-color: #343a40;
    border: none;
    border-radius: 8px;
}

.acc-setting .edit-button {
    color: #3898ff;
    transition: color 0.3s ease;
    cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.acc-setting .edit-button:hover {
    color: blue;
}

.acc-setting .card-body {
    padding: 20px;
}

.acc-setting .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.acc-setting .form-label {
    font-size: 1rem;
    color: #adb5bd;
}

.acc-setting .form-control {
    background-color: #495057;
    color: #f8f9fa;
    border: 1px solid #6c757d;
    border-radius: 5px;
}

.acc-setting .form-control:focus {
    background-color: #495057;
    color: #f8f9fa;
    border-color: #007bff;
}

.disabled-input {
    cursor: not-allowed;
    color: #768088 !important;
    background-color: #383e44  !important;
}


.acc-setting .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.acc-setting .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.acc-setting .btn-link {
    color: #ff4040;
    text-decoration: none;
}

.acc-setting .btn-link:hover {
    color: #dc3545;
}

.btn-disabled {
    color: #6c757d;
    cursor: not-allowed;
}