.address-card {
    background-color: #000000;
    color: #fff;
    border: 1px solid #495057;
    position: relative;
}

.dropdown-toggle::after {
    display: none;
}

.bi-three-dots {
    font-size: 1.2rem;
}

.dropdown-menu {
    background-color: #000000;
    border: 1px solid #000000;
}

.dropdown-item {
    color: #fff;
}

.dropdown-item:hover {
    color: white;
    background-color: #5f5f5f;
}