.order-card {
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: white;
    background-color: #1c1c1c;
    transition: border-color 0.4s ease-in-out;
}

.order-card:hover {
    border: 1px solid #28a745;
    cursor: pointer;
}

.order-item {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    padding-bottom: 10px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
}

.order-image {
    margin-right: 15px;
}

.order-item-name {
    font-size: 1.2rem;
    margin: 0;
}

.payment-method-container {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
}

.payment-method-container.show {
    opacity: 1;
    max-height: 500px;
}

.payment-method-container.hide {
    opacity: 0;
    max-height: 0;
}


.order-item-color,
.order-item-size,
.order-item-quantity {
    margin: 0;
}

.order-summary {
    text-align: right;
}

.order-item-total-price {
    font-weight: bold;
}

.order-status {
    font-weight: bold;
}

.text-warning {
    color: #ffc107;
}

.text-success {
    color: #28a745;
}