/* Cart.css */

.cart-item-image img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.cart-item-details {
    flex: 1;
}

.cart-item-details .btn {
    width: 30px;
    height: 30px;
    padding: 0;
}

.cart-quantity{
    width: 5px;
}


.address-card h5 {
    margin-bottom: 1rem;
}

.cart-item {
    cursor: pointer;
    color: white;
    border: 1px solid #ddd;
    transition: border-color 0.3s ease;
}

.cart-item:hover {
    border-color: #28a745;
}


.cart-item img {
    max-width: 100%;
}

.modal-body .form-check {
    margin-bottom: 10px;
}

.no-cart-items {
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 50px auto;
}

.no-cart-items h4 {
    font-weight: bold;
    color: #ffffff;
}

.no-cart-items p {
    color: #b3b3b3;
    font-size: 1.1rem;
}

.shop-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.shop-button:hover {
    background-color: #28a745;
}
