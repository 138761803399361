.cards-active{
    transition: transform 0.3s ease, text-shadow 0.3s ease;
}
.cards-active:hover{
    transform: scale(1.01);
}

.dashboard-container p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.dashboard-container button{
    min-width: 0;
}

