@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');


.error-container .err-msg {
    margin-top: 25%;
}

.page_403 {
    padding: 56px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.gif-div{
    padding-left: 15rem;
}
.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 700px;
    background-position: center;
}


.four_zero_four_bg h1 {
    font-size: 80px;
    font-family: "Orbitron", sans-serif;
}
.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_403 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px;
}

.contant_box_403 {
    margin-top: -50px;
}

.contant_box_403 .h2{
    text-transform: uppercase;
}