.admin-container {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 250px;
  height: 100%;
  background-color: #2c3e50;
  color: #fff;
  padding: 20px;
  border-right: 1px solid #34495e;
}

.sidebar-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

.sidebar-nav ul {
  list-style: none;
  padding: 0;
}

.sidebar-nav ul li {
  margin: 15px 0;
}

.sidebar-nav ul li a {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background 0.3s, transform 0.2s;
}

.sidebar-nav ul li a:hover {
  background-color: #2980b9;
  transform: translateX(5px);
}

.sidebar-nav ul li a i {
  margin-right: 10px;
}

.admin-container .active {
  background-color: #3498db;
  color: white;
}

.content {
  flex: 1;
  padding: 20px;
  color: black;
  background-color: #ecf0f1;
  max-width: 87%;
}

.content h2 {
  margin-bottom: 20px;
}

.content-main {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.admin-container .logout-btn {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background 0.3s, transform 0.2s;
}

.admin-container .logout-btn:hover {
  background-color: #2980b9;
  transform: translateX(5px);
}