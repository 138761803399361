.user-home-page {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    animation: fadeIn 1.5s ease-in-out; /* Fade in effect */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.home-content {
    height: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center; /* Centering vertically */
    justify-content: space-between; /* Spacing out heading and banner */
    opacity: 0;
    transform: translateY(30px);
    animation: fadeUp 1.2s ease-in-out forwards; /* Fade-up effect */
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.home-heading {
    max-width: 500px;
    z-index: 1; /* Ensuring content is above any overlay */
}

.home-heading h1 {
    font-size: 2.5rem;
    font-weight: bold;
    transition: transform 0.3s ease, color 0.3s ease; /* Adding transition */
}

.home-heading h1:hover {
    transform: scale(1.05); /* Scale effect on hover */
    color: #f0f0f0; /* Change color on hover */
}

.home-heading p {
    font-size: 1.2rem;
    margin-top: 1rem;
    transition: color 0.3s ease; /* Adding transition */
}

.home-heading p:hover {
    color: #f0f0f0; /* Change color on hover */
}

.home-banner {
    z-index: 1; /* Ensuring banner is above any overlay */
}

.home-banner img {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease; /* Adding transition for zoom effect */
}

.home-banner img:hover {
    transform: scale(1.05); /* Zoom effect on hover */
}

/* Optional: Dark overlay to improve text visibility */
.user-home-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Dark overlay */
    z-index: 0; /* Positioning below content */
}
