.single-product-container {
  margin-top: 2rem;
}

.single-product-container .product-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.single-product-container .additional-images {
  flex: 0 0 125px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 1rem;
}



.single-product-container .additional-images img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid transparent;
}

.single-product-container .additional-images img:hover {
  border: 2px solid #007bff;
}

.single-product-container .product-image {
  flex: 0 0 515px;
  position: relative;
  text-align: center;
  z-index: 10;
}


.single-product-container .main-image {
  max-width: 100%;
  height: 515px;
  object-fit: cover;
}

.single-product-container .product-info {
  flex: 1;
  padding-left: 2rem;
  text-align: left;
}

.single-product-container .product-info h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.single-product-container .product-info .brand,
.single-product-container .product-info .category {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.single-product-container .product-info .price {
  font-size: 1.8rem;
  color: #28a745;
  margin-bottom: 1rem;
}

.single-product-container .product-info .discount-price {
  text-decoration: line-through;
  color: #dc3545;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}



.single-product-container .add-to-cart-btn {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}

.single-product-container .add-to-cart-btn .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 4px;
}

.single-product-container .colors-container {
  background: linear-gradient(270deg, #2c3e50, #34495e, #2c3e50, #3a506b);
  background-size: 600% 600%;
  animation: gradientAnimation 15s ease infinite;
  padding: 10px;
}

.sizes .non-active {
  background-color: black;
  color: white;
}

.sizes .active {
  background: linear-gradient(to bottom, black, rgb(108, 108, 108));
}

.sizes .active-variation {
  background-color: rgb(156, 156, 156);
  color: white;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.single-product-container .wishlist-icon-container {
  position: absolute;
  font-size: x-large;
  top: 10px;
  right: 10px;
  z-index: 11;
}

/* Related products */
.related-products .products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Ensures 4 items in a row */
  gap: 20px;
}

.related-products .product-card {
  width: 318px;
  display: grid;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.related-products .shopping-page-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #121212;
  padding: 10px;
  position: relative;
}

.related-products .product-image {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.related-products .product-details {
  margin-top: 10px;
  text-align: center;
}

.related-products .product-name {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #333;
}

.related-products .truncate-text {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-products .product-current-price {
  margin-top: 5px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #333;
}

.related-products .product-original-price {
  margin-top: 5px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #ccc;
  text-decoration: line-through;
}
.related-products .product-actions {
  margin-top: 10px;
  display: flex;
}
.related-products .product-background {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 50%;
}