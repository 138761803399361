.success-page .order-success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 9999;
    overflow-y: auto;
}

.success-page .order-success-content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 40px;
    max-width: 800px;
    margin: 20px;
}

.success-page .success-title {
    color: #28a745;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.success-page .success-message {
    color: #333;
    font-size: 1.1rem;
    margin-bottom: 30px;
}

.success-page .order-details {
    background: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

.success-page .order-details h2 {
    color: #007bff;
    margin-bottom: 15px;
}

.success-page .order-details p {
    font-size: 1rem;
    color: #333;
    margin: 5px 0;
}

.success-page .button-container {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.success-page  .btn {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    transition: background-color 0.3s ease;
}

.success-page  .btn-primary {
    background-color: #007bff;
}

.success-page  .btn-primary:hover {
    background-color: #0056b3;
}

.success-page  .btn-secondary {
    background-color: #6c757d;
}

.success-page .btn-secondary:hover {
    background-color: #5a6268;
}