

.invoice {
    width: 210mm; /* A4 width */
    padding: 20px;
    max-height: 0;
    margin: 0 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.6s ease, opacity 0.6s ease;
}


.invoice.show {
    margin: 10px 0;
    max-height: 1000px; /* Ensure this is large enough to show the full invoice */
    opacity: 1;
}
.invoice-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.invoice-details.card,
.invoice-products.card {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
}

.invoice-details .left,
.invoice-details .right {
    display: inline-block;
    width: 48%;
    vertical-align: top;
}

.invoice-details .left {
    margin-right: 4%;
}

.invoice-products table {
    width: 100%;
    border-collapse: collapse;
}

.invoice-products th,
.invoice-products td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.grand-total {
    text-align: right;
    font-size: 20px;
    font-weight: bold;
}

.button-container {
    margin-top: 20px;
    text-align: center;
}
