.sidebar-categories {
    background: #f9f9f9;
    border: 1px solid #e1e1e1;
    padding: 15px;
    border-radius: 5px;
    margin-top: 0.76rem;

}

.sidebar-categories .head {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.sidebar-categories .main-categories {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-categories .main-nav-list {
    margin-bottom: 10px;
}

.sidebar-categories .main-nav-list a {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 16px;
    text-decoration: none;
    padding: 10px;
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.sidebar-categories .main-nav-list a:hover {
    background: #f1f1f1;
}

.sidebar-categories .main-nav-list .lnr-arrow-right {
    margin-right: 10px;
}

.sidebar-categories .main-nav-list .number {
    color: #000000;
    border-radius: 50%;
    font-size: 15px;
}

.sidebar-categories .collapse {
    margin-top: 10px;
    padding-left: 15px;
}

.sidebar-categories .child a {
    display: flex;
    justify-content: space-between;
    align-items: start;
    color: #333;
    font-size: 14px;
    text-decoration: none;
    padding: 8px;
    background: #f1f1f1;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.sidebar-categories .child a:hover {
    background: #e1e1e1;
}

.shopping-products {
    top: 0rem;
}

.search {
    position: relative;
    max-width: 300px;
}

.search input {
    width: 100%;
    padding-right: 40px;
}

.search-icon {
    position: absolute;
    right: 10px;
    color: #aaa;
}


.sidebar-categories {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.head {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

.price-filter {
    display: flex;
    gap: 1rem;
}

.price-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.price-input label {
    font-size: 0.875rem;
    color: #555;
}

.price-input input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.price-input input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.price-input input::placeholder {
    color: #999;
}


.offer-badge {
    color: rgb(88, 231, 0);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;

}



.price-input input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 14px;
}

.price-suggestions {
    margin-top: 10px;
    display: flex;
}

.suggestion {
    padding: 8px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
    text-align: center;
    font-size: 12.7px;
    transition: background-color 0.5s, color 0.5s ease;
}

.suggestion:hover {
    background-color: black;
    color: white;
}

.selected-filter {
    background-color: black !important;
    color: white !important;
}




/* Products */
.shopping-products{
    margin-top: 1rem;
}
.shopping-products .products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensures 4 items in a row */
    gap: 20px; /* Adjust the gap between the product cards */
}

.shopping-products .product-card {
    width: 318px;
    display: grid;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shopping-products .shopping-page-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #333;
    padding: 10px;
    position: relative;
}
.shopping-products .product-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.shopping-products .product-details {
    margin-top: 10px;
    text-align: center;
}

.shopping-products .product-name {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #333;
}

.shopping-products .truncate-text {
    display: inline-block;
    max-width: 200px;  /* Set a maximum width for truncation */
    white-space: nowrap;  /* Ensure text doesn't wrap to the next line */
    overflow: hidden;  /* Hide overflowed text */
    text-overflow: ellipsis;  /* Add the ellipsis ('...') */
}

.shopping-products .product-current-price {
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #333;
}

.shopping-products .product-original-price {
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ccc;
    text-decoration: line-through;
}

.shopping-products .product-actions {
    margin-top: 10px;
    display: flex;
}

.shopping-products .offer-badge {
    color: rgb(88, 231, 0);
    padding: 0 0;
    border-radius: 5px;
    font-size: 14px;
    min-height: 21px;
    visibility: hidden;
}

.shopping-products .offer-badge:empty::before {
    content: '\00a0';
}

.shopping-products .offer-badge:not(:empty) {
    visibility: visible;
}


.shopping-products .product-background {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #f0f0f0;
    border-radius: 50%;
}

.shopping-products .product-background i {
    color: black;
    font-size: large;
}

.shopping-products .product-icon {
    width: 24px;
    height: 24px;
}
