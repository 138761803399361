.wallet-page {
    font-family: 'Poppins', sans-serif;
    background-color: #000000;
}

.premium-card {
    border-radius: 12px;
    border: none;
}

.premium-card .card-header {
    background-color: #444444;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

.premium-btn {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.premium-btn:hover {
    background-color: #0056b3;
    color: white;
}

.premium-label {
    font-weight: 600;
}

.premium-input {
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #d9d9d9;
    transition: border-color 0.3s ease;
}

.premium-input:focus {
    border-color: #007bff;
}



.premium-tabs .nav-link.active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.premium-icon {
    font-size: 1.5rem;
}

.fade-in {
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.animated-btn {
    position: relative;
    overflow: hidden;
}

.animated-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: left 0.5s;
}

.animated-btn:hover::before {
    left: 100%;
}


.premium-card {
    border-radius: 12px;
    border: none;
}

.premium-tabs .nav-link {
    color: #495057;
    font-weight: bold;
}

.premium-tabs .nav-link.active {
    color: #0fde00;
    background-color: #f8f9fa;
    border-bottom: 3px solid #007bff;
}

.transaction-list {
    max-height: 269px; /* Limit the height */
    overflow-y: auto; /* Make it scrollable if there are more than 5 items */
}

.no-transaction-item {
    text-align: center;
    font-weight: bold;
    color: #6c757d;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;
    transition: background-color 0.3s ease;
}

.no-transaction-item:hover {
    background-color: #e9ecef;
}

.premium-list-item {
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s ease;
}

.premium-list-item:hover {
    background-color: #f8f9fa;
}

.transaction-list::-webkit-scrollbar {
    width: 8px;
}

.transaction-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.transaction-list::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 10px;
}
